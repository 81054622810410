<template>
  <v-container>
    <v-card outlined class="mb-3">
      <v-toolbar flat dense color="primary">
        <v-toolbar-title>Statystyki zawodnika</v-toolbar-title>
      </v-toolbar>
      <v-card-text>

        <!-- Select competitor -->
        <v-row>
          <v-col cols="12">
            <v-autocomplete label="Zawody" v-model="competition" :items="competitions"
                            item-text="name" item-value="id" hide-details/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="7">
            <v-autocomplete label="Zawodnik" v-model="competitor" :items="competitors"
                            item-text="name" item-value="id" hide-details/>
          </v-col>
          <v-col cols="12" md="5">
            <v-autocomplete label="Konkurencja" v-model="event" :items="events"
                            item-text="name" item-value="id" hide-details/>
          </v-col>
        </v-row>

        <!-- Show stats -->
        <v-card v-if="stats != null" outlined class="mt-4">
          <v-card-title>{{ stats.name + ' - ' + events.find(e => e.id === stats.event)['name'] }}</v-card-title>
          <v-card-subtitle>
            RA ID: {{ stats['raId'] != null ? stats['raId'] : '-' }}<br>
            WCA ID: {{ stats['wcaId'] != null ? stats['wcaId'] : '-' }}
          </v-card-subtitle>
          <v-card-text>

            <!-- Records -->
            <div v-if="stats.globalPersonalBest.s != null">
              Rekord w <b>singlu</b>: <b class="primary--text">
              {{ $utils.results.parseResult(stats.globalPersonalBest.s, events.findIndex(e => e.id === event)) }}</b>
              (<b class="primary--text">{{ stats.globalRanking.s }}.</b> pozycja w rankingu)<br>
            </div>
            <div v-if="stats.globalPersonalBest.a != null">
              Rekord w <b>średniej</b>: <b class="primary--text">
              {{ $utils.results.parseResult(stats.globalPersonalBest.a, events.findIndex(e => e.id === event)) }}</b>
              (<b class="primary--text">{{ stats.globalRanking.a }}.</b> pozycja w rankingu)<br>
            </div>
            <div v-if="stats.competitionPersonalBest.s != null">
              Najlepszy <b>singiel</b> na wybranych zawodach: <b class="primary--text"> {{
                $utils.results.parseResult(stats.competitionPersonalBest.s, events.findIndex(e => e.id === event))
              }}</b><span v-if="stats.progress.s != null && stats.progress.s >= 0 && event !== '333mbf'"> (
              <b class="primary--text"> - {{
                  $utils.results.parseResult(stats.progress.s, events.findIndex(e => e.id === event))
                }}</b> )</span>
            </div>
            <div v-if="stats.competitionPersonalBest.a != null">
              Najlepsza <b>średnia</b> na wybranych zawodach: <b class="primary--text"> {{
                $utils.results.parseResult(stats.competitionPersonalBest.a, events.findIndex(e => e.id === event))
              }}</b><span v-if="stats.progress.a != null && stats.progress.a >= 0 && event !== '333mbf'"> (
              <b class="primary--text"> - {{
                  $utils.results.parseResult(stats.progress.a, events.findIndex(e => e.id === event))
                }}</b> )</span>
            </div>

            <!-- Starts -->
            <v-divider class="mt-3 mb-3"/>
            <div>
              Liczba <b>zawodów*</b>: <b class="primary--text">{{ stats.starts.competitions }}</b><br>
              Liczba <b>rund</b>: <b class="primary--text">{{ stats.starts.rounds }}</b><br>
              Liczba <b>finałów</b>: <b class="primary--text">{{ stats.starts.finals }}</b><br>
              Liczba <b>prób</b>: <b class="primary--text">{{ stats.starts.attempts }}</b><br>
              Liczba <b>udanych prób</b>: <b class="primary--text">{{ stats.starts.successes }}</b><br>
              <b>Skuteczność</b>: <b class="primary--text">{{
                stats.starts.attempts > 0 ? Math.round(100 * stats.starts.successes / stats.starts.attempts) + '%' : '-'
              }}</b><br>
              * - zawody, w których zawodnik wziął udział w wybranej konkurencji
            </div>

            <!-- Medals -->
            <v-divider class="mt-3 mb-3"/>
            <div>
              Zdobytych <b>medali</b>: <b
                class="primary--text">{{ stats.medals.gold + stats.medals.silver + stats.medals.bronze }}</b>.
              W tym
              <br>- <b>złotych</b>: <b class="primary--text">{{ stats.medals.gold }}</b>&nbsp;
              <v-icon color="yellow darken-1">mdi-trophy</v-icon>
              <br>- <b>srebrnych</b>: <b class="primary--text">{{ stats.medals.silver }}</b>&nbsp;
              <v-icon color="grey">mdi-trophy</v-icon>
              <br>- <b>brązowych</b>: <b class="primary--text">{{ stats.medals.bronze }}</b>&nbsp;
              <v-icon color="brown">mdi-trophy</v-icon>
            </div>

            <!-- Finals -->
            <v-divider v-if="stats.finals.some(f => f !== 0)" class="mt-3 mb-3"/>
            <div v-if="stats.finals.some(f => f !== 0)">
              Zawodnik zajął <b> w finałach</b>:
              <div v-for="(times, place) in stats.finals" :key="place">
                <div v-if="times === 1">
                  <b class="primary--text">{{ place }}.</b> miejsce <b class="primary--text">{{ times }}</b> raz
                </div>
                <div v-if="times > 1">
                  <b class="primary--text">{{ place }}.</b> miejsce <b class="primary--text">{{ times }}</b> razy
                </div>
              </div>
            </div>

            <!-- Results (competition) -->
            <v-divider v-if="stats.results.length > 0" class="mt-3 mb-3"/>
            <div v-if="stats.results.length > 0">
              Wyniki zawodnika w wybranej konkurencji na wybranych zawodach:<br>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">Runda</th>
                    <th class="text-center">Pozycja</th>
                    <th class="text-center">Najlepsze</th>
                    <th class="text-center">Średnia</th>
                    <th class="text-center">Szczegóły</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="result in stats.results" :key="result.round.id">
                    <td class="text-left">{{ result.round.name }}</td>
                    <td class="text-center">{{ result.ranking }}</td>
                    <td class="text-center">
                      {{ $utils.results.parseResult(result.best, events.findIndex(e => e.id === event)) }}
                    </td>
                    <td class="text-center">
                      {{ $utils.results.parseResult(result.average, events.findIndex(e => e.id === event)) }}
                    </td>
                    <td class="text-center">
                      {{
                        result.attempts.map(a => $utils.results.parseResult(a, events.findIndex(e => e.id === event))).join(' / ')
                      }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'AdminStatsCompetitor',
  data: () => ({
    competitions: [], competition: null, competitors: [], competitor: null, event: null, stats: null,
    events: [{id: '333', name: 'Kostka 3x3x3'}, {id: '222', name: 'Kostka 2x2x2'}, {id: '444', name: 'Kostka 4x4x4'},
      {id: '555', name: 'Kostka 5x5x5'}, {id: '666', name: 'Kostka 6x6x6'}, {id: '777', name: 'Kostka 7x7x7'},
      {id: '333bf', name: '3x3x3 Bez patrzenia'}, {id: '333fm', name: '3x3x3 Najmniejsza liczba ruchów'},
      {id: '333oh', name: '3x3x3 Jedną ręką'}, {id: 'clock', name: 'Clock'}, {id: 'minx', name: 'Megaminx'},
      {id: 'pyram', name: 'Pyraminx'}, {id: 'skewb', name: 'Skewb'}, {id: 'sq1', name: 'Square-1'},
      {id: '444bf', name: '4x4x4 Bez patrzenia'}, {id: '555bf', name: '5x5x5 Bez patrzenia'},
      {id: '333mbf', name: '3x3x3 Wiele kostek bez patrzenia'}
    ] // TODO make events global
  }),
  computed: {
    eventIndex() {
      return this.events.findIndex(e => e.id === this.event)
    }
  },
  created() {
    this.$http.post('/api/admin/live/competitions').then((res) => {
      this.competitions = res.data;
    }).catch((e) => {
      console.log(e);
    });
  },
  methods: {
    loadStats() {
      if (this.competition == null || this.competitor == null || this.event == null) return;
      this.$http.post('/api/admin/stats/competitor', {
        competitionId: this.competition, competitorId: this.competitor, eventId: this.event
      }).then((res) => {
        this.stats = res.data;
      }).catch((e) => {
        console.log(e);
      });
    }
  },
  watch: {
    competition() {
      this.$http.post('/api/admin/live/competitors', {id: this.competition}).then((res) => {
        this.competitors = res.data;
      }).catch((e) => {
        console.log(e);
      });
    },
    competitor() {
      this.loadStats();
    },
    event() {
      this.loadStats();
    }
  }
};
</script>
